import React from "react"
import SwaggerUI from "swagger-ui-react"
import "swagger-ui-react/swagger-ui.css"
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import Section from '../../components/section'
import { StaticQuery, graphql } from "gatsby"

const Developers = () => {
	return <Layout>
		<SEO title="Developers"/>
		<Section>
		<StaticQuery query={graphql`
		{
			file(relativePath: { eq: "openapi.txt" }) {
				childPlainText {
					content
				}
			}
		}
		`}
			render={data => <SwaggerUI spec={JSON.parse(data.file.childPlainText.content)}/>}
		/>
	</Section></Layout>
}

export default Developers;